import * as Yup from 'yup';

export const validationSchemaObject = Yup.object().shape({
  uuid: Yup.string().notRequired(),
  name: Yup.string().notRequired(),
  showDiscountFlag: Yup.string().required(),
  status: Yup.string().notRequired(),
  confirmDelete: Yup.string().notRequired().nullable(),
  phone: Yup.string().notRequired(),
  creditRefresh: Yup.boolean().required(),
  noCreditCheck: Yup.boolean().required(),
});

export const FORM_INPUT_FIELDS = [
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'primaryContactName',
    label: 'Primary Contact',
  },
  {
    value: 'dotNumber',
    label: 'Dot Number',
  },
  {
    value: 'publicId',
    label: 'Public ID',
    readonly: true,
  },
  {
    value: 'externalId',
    label: 'External ID',
    readonly: true,
  },
  {
    value: 'phone',
    label: 'Phone Number',
  },
  {
    value: 'address.street1',
    label: 'Street 1',
  },
  {
    value: 'address.city',
    label: 'City',
  },
  {
    value: 'address.state',
    label: 'State',
  },
  {
    value: 'address.zipCode',
    label: 'Zipcode',
  },
  {
    value: 'carrierCreditAlertAmount',
    label: 'Credit Alert',
  },
];

export namespace CarrierStatus {
  export const CARRIER_STATUS_ACTIVE = 'CARRIER_STATUS_ACTIVE';
  export const CARRIER_STATUS_INACTIVE = 'CARRIER_STATUS_INACTIVE';
  export const CARRIER_STATUS_SUSPENDED = 'CARRIER_STATUS_SUSPENDED';
  export const CARRIER_STATUS_DELETED = 'CARRIER_STATUS_DELETED';

  const statusTypes = [
    {
      name: 'Active',
      value: 'CARRIER_STATUS_ACTIVE',
    },
    {
      name: 'Inactive',
      value: 'CARRIER_STATUS_INACTIVE',
    },
    {
      name: 'Suspended',
      value: 'CARRIER_STATUS_SUSPENDED',
    },
    {
      name: 'Deleted',
      value: 'CARRIER_STATUS_DELETED',
    },
  ];
  export function getByValue(typeValue: string) {
    return statusTypes.find((x) => x.value === typeValue);
  }
  export function toOptions() {
    return statusTypes;
  }
}

export namespace ShowDiscountType {
  export const SHOW_DISCOUNT_TO_DRIVERS_ALWAYS =
    'SHOW_DISCOUNT_TO_DRIVERS_ALWAYS';
  export const SHOW_DISCOUNT_TO_DRIVERS_NEVER =
    'SHOW_DISCOUNT_TO_DRIVERS_NEVER';
  export const SHOW_DISCOUNT_TO_DRIVERS_UNSPECIFIED =
    'SHOW_DISCOUNT_TO_DRIVERS_UNSPECIFIED';

  const showDiscountTypes = [
    {
      name: 'Always',
      value: true,
    },
    {
      name: 'Never',
      value: false,
    },
  ];

  export function toOptions() {
    return showDiscountTypes;
  }
}

export namespace CreditRefresh {
  const enableCreditRefresh = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  export function toOptions() {
    return enableCreditRefresh;
  }
}

export namespace NoCreditCheck {
  const enableNoCreditCheck = [
    {
      name: 'Yes',
      value: true,
    },
    {
      name: 'No',
      value: false,
    },
  ];

  export function toOptions() {
    return enableNoCreditCheck;
  }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
