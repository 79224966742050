export const EXPORT_FILE_TYPES = {
  CSV: {
    value: 'FILE_TYPE_CSV',
    name: 'csv',
  },
  PDF: {
    value: 'FILE_TYPE_PDF',
    name: 'pdf',
  },
  XLS: {
    value: 'FILE_TYPE_XLS',
    name: 'xls',
  },
};
