import { Config } from '@/aggregator/config/env';

export namespace TaskType {
  export const TASK_URL_CARRIER_TRANSACTION =
    Config.TASK_URL_CARRIER_TRANSACTION;

  const reportTypes = [
    {
      name: 'Transaction Report',
      value: TASK_URL_CARRIER_TRANSACTION,
      customer: 'carrier',
    },
    {
      name: 'Transaction Report',
      value: TASK_URL_CARRIER_TRANSACTION,
      customer: 'program',
    },
  ];

  export function getByCustomer(customer: string) {
    return reportTypes.find((x) => x.customer === customer);
  }

  export function getByName(name: string) {
    return reportTypes.find((x) => x.name === name);
  }
}
