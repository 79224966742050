import * as React from 'react';
import toast from 'react-hot-toast';
import { useApolloClient, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  Box,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  OutlinedInput,
  MenuItem,
  ListItemText,
  InputLabel,
  Select,
  Checkbox,
  Chip,
  Autocomplete,
} from '@mui/material';
import { get } from 'lodash';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import { SecondaryButton, PrimaryButton } from '@/aggregator/ui/inputs/Button';
import { PathPrivate } from '@/aggregator/paths/constants';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import * as typesMerchants from '@/aggregator/pages/private/merchants/types';
import * as queriesMerchants from '@/aggregator/pages/private/merchants/queries';
import * as helpers from './helpers';
import * as mutations from './mutations';
import * as queries from './queries';

interface MerchatLocation {
  uuid: string;
  name: string;
  address: {
    city: string;
    region: string;
  };
  storeNumber: string;
}

export function CarrierEdit() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { carrierId = '' } = useParams();

  const responseMerchants = useQuery<{
    getMerchants: typesMerchants.MerchantType[];
  }>(queriesMerchants.QUERY_GET_MERCHANTS);

  const [locations, setLocations] = React.useState([]);
  const [loadingLocations, setLoadingLocations] = React.useState(true);

  React.useEffect(() => {
    const getData = async () => {
      await queries.getMerchantsLocations().then((response: any) => {
        if (response.isAxiosError) {
          throw Error(response.response.data.message);
        } else {
          setLocations(response.data.locations);
        }
        setLoadingLocations(false);
      });
    };
    getData();
  }, []);

  if (loadingLocations || responseMerchants.loading) {
    return <CircularProgress />;
  }

  const handleOnClose = (shouldRefetch: boolean) => {
    navigate(PathPrivate.Carriers, { state: { shouldRefetch } });
  };

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  if (!carrier) return <div>Carrier not found</div>;
  if (!responseMerchants.data) return <p>No data available</p>;

  const merchants = responseMerchants.data.getMerchants;

  const getMerchantName = (uuid: string) => {
    let merchantName = '';
    merchants.map((merchant: any) => {
      if (merchant.uuid === uuid) {
        merchantName = merchant.name;
      }
    });
    return merchantName;
  };

  const getLocationName = (uuid: string) => {
    const location = locations.find((loc: any) => loc.uuid === uuid);

    if (location) {
      const {
        name: locationName,
        address: { city, region: state },
        storeNumber,
      } = location;
      return { locationName, city, state, storeNumber };
    }

    return { locationName: '', city: '', state: '', storeNumber: '' };
  };

  return (
    <Formik
      initialValues={{
        ...carrier,
        confirmDelete: null,
      }}
      validationSchema={helpers.validationSchemaObject}
      enableReinitialize
      onSubmit={(values) => {
        const enabledMerchants = merchants
          ?.filter(
            (merchant: { uuid: string }) =>
              !values.disabledMerchants.includes(merchant.uuid),
          )
          .map((merchant: { uuid: string }) => merchant.uuid);

        const enabledLocations = locations
          ?.filter(
            (location: { uuid: string }) =>
              !values.disabledLocations.includes(location.uuid),
          )
          .map((merchant: { uuid: string }) => merchant.uuid);

        const parsedValues = {
          ...values,
          enabledMerchants,
          enabledLocations,
          showDiscountFlag:
            values.showDiscountFlag === 'true' ||
            values.showDiscountFlag === true
              ? 'SHOW_DISCOUNT_TO_DRIVERS_ALWAYS'
              : 'SHOW_DISCOUNT_TO_DRIVERS_NEVER',
          creditRefresh: values.creditRefresh === 'true',
          noCreditCheck:
            values.noCreditCheck === 'true' || values.noCreditCheck === true
              ? true
              : false,
        };

        mutations
          .updateCarrier(parsedValues)
          .then(() => {
            toast.success('Carrier updated');
            handleOnClose(true);
          })
          .catch((e: any) => {
            if (e.response) {
              toast.error(e.response.data.message);
            } else {
              toast.error(e.message);
            }
          });
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        touched,
        errors,
        setFieldValue,
      }) => {
        return (
          <Dialog open onClose={() => handleOnClose(false)}>
            <DialogTitle sx={{ textAlign: 'center' }}>
              Edit Carrier: {carrier.name}
            </DialogTitle>
            <Form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box mt={1} mx={1} display="flex" flexDirection="column">
                  {helpers.FORM_INPUT_FIELDS.map((field) => {
                    const { value, label, readonly = false } = field;
                    const isError = Boolean(
                      touched[value as keyof typeof touched] &&
                        errors[value as keyof typeof errors],
                    );

                    return (
                      <TextField
                        disabled={readonly}
                        key={`${label}`}
                        id={label}
                        label={label}
                        variant="outlined"
                        fullWidth
                        name={value}
                        value={get(values, value)}
                        onChange={handleChange}
                        sx={{ marginBottom: 2 }}
                        error={isError}
                      />
                    );
                  })}
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="carrierAlertEmails"
                      sx={{ mb: 1 }}
                      options={[]}
                      value={values.carrierAlertEmails}
                      onChange={(_, newValue) => {
                        setFieldValue('carrierAlertEmails', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Carrier email recipients"
                          error={
                            touched.carrierAlertEmails &&
                            !!errors.carrierAlertEmails
                          }
                          helperText="Press Enter to add to recipients list"
                        />
                      )}
                    />
                    <Autocomplete
                      multiple
                      freeSolo
                      sx={{ mb: 1 }}
                      id="programAlertEmails"
                      options={[]}
                      value={values.programAlertEmails}
                      onChange={(_, newValue) => {
                        setFieldValue('programAlertEmails', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Program email recipients"
                          error={
                            touched.programAlertEmails &&
                            !!errors.programAlertEmails
                          }
                          helperText="Press Enter to add to recipients list"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <FormLabel>Show discounts on driver receipt</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="showDiscountFlag"
                      value={values.showDiscountFlag}
                      onChange={handleChange}
                      row
                    >
                      {helpers.ShowDiscountType.toOptions().map((x, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            control={<Radio />}
                            value={x.value}
                            label={x.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <FormLabel>Credit refresh</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="creditRefresh"
                      value={values.creditRefresh}
                      onChange={handleChange}
                      row
                    >
                      {helpers.CreditRefresh.toOptions().map((x, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            control={<Radio />}
                            value={x.value}
                            label={x.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <FormLabel>No Credit Check</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="noCreditCheck"
                      value={values.noCreditCheck}
                      onChange={handleChange}
                      row
                    >
                      {helpers.NoCreditCheck.toOptions().map((x, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            control={<Radio />}
                            value={x.value}
                            label={x.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <FormLabel>Carrier Status</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      row
                    >
                      {helpers.CarrierStatus.toOptions().map((x, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            control={<Radio />}
                            value={x.value}
                            label={x.name}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  {values.status === 'CARRIER_STATUS_DELETED' && (
                    <FormControl>
                      <FormLabel>
                        Are you sure you want to delete this carrier?
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="confirmDelete"
                        value={values.confirmDelete}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="yes"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="no"
                          label="No"
                        />
                      </RadioGroup>
                      {values.confirmDelete === 'no' ? (
                        <Box color="red">
                          Select a different Carrier Status or &apos;Yes&apos;
                          if you want to delete this carrier in order to proceed
                        </Box>
                      ) : null}
                    </FormControl>
                  )}
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="multiple-checkbox-label">
                      Disabled Merchants
                    </InputLabel>
                    <Select
                      name="disabledMerchants"
                      labelId="multiple-checkbox-label"
                      id="multiple-checkbox"
                      multiple
                      value={values.disabledMerchants}
                      onChange={(e) => {
                        setFieldValue('disabledMerchants', e.target.value);
                      }}
                      input={<OutlinedInput label="Disabled Merchants" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {selected.map((value: string) => {
                            const merchantName = getMerchantName(value);
                            return <Chip key={value} label={merchantName} />;
                          })}
                        </Box>
                      )}
                      MenuProps={helpers.MenuProps}
                    >
                      {merchants.map((merchant, idx: number) => {
                        return (
                          <MenuItem key={idx} value={merchant.uuid}>
                            <Checkbox
                              checked={
                                values.disabledMerchants.indexOf(
                                  merchant.uuid,
                                ) > -1
                              }
                            />
                            <ListItemText primary={merchant.name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="multiple-checkbox-label">
                      Disabled Locations
                    </InputLabel>
                    <Select
                      name="disabledLocations"
                      labelId="multiple-checkbox-label"
                      id="multiple-checkbox"
                      multiple
                      value={values.disabledLocations}
                      onChange={(e) => {
                        setFieldValue('disabledLocations', e.target.value);
                      }}
                      input={<OutlinedInput label="Disabled Locations" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {selected.map((value: string) => {
                            const { locationName, city, state, storeNumber } =
                              getLocationName(value);
                            return (
                              <Chip
                                key={value}
                                label={`${locationName} - ${city}, ${state} - ${storeNumber}`}
                              />
                            );
                          })}
                        </Box>
                      )}
                      MenuProps={helpers.MenuProps}
                    >
                      {locations.map((location: MerchatLocation) => (
                        <MenuItem key={location.uuid} value={location.uuid}>
                          <Checkbox
                            checked={
                              values.disabledLocations.indexOf(location.uuid) >
                              -1
                            }
                          />
                          <ListItemText
                            primary={`${location.name} - ${location.address.city}, ${location.address.region} - ${location.storeNumber}`}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <SecondaryButton onClick={() => handleOnClose(false)}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={
                    values.status === 'CARRIER_STATUS_DELETED' &&
                    (values.confirmDelete === 'no' ||
                      values.confirmDelete === null)
                  }
                >
                  Save
                </PrimaryButton>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
}
