import { setHours, setMinutes, setSeconds } from 'date-fns';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
});

export const defaultStartDate = setSeconds(
  setMinutes(setHours(new Date(), 0), 0),
  0,
);
export const defaultEndDate = setSeconds(
  setMinutes(setHours(new Date(), 23), 59),
  59,
);
