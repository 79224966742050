import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { PathPrivate } from '@/aggregator/paths/constants';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as queries from '../../../merchants/queries';
import * as types from '../../../merchants/types';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { format } from 'date-fns';
import { getProgramUuid } from '@/aggregator/config/auth';
import { retrieveProgram, setProgramFeesService } from '../../queries';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';

const FEE_CATEGORY = 'FEE_CATEGORY_PROGRAM_MARKETING';

const validationSchema = Yup.object().shape({
  fees: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Required'),
      merchantUuid: Yup.string().required('Required'),
      carrierUuid: Yup.string().optional(),
      perGallonAmount: Yup.number().when('type', {
        is: (type: string) => type === 'FEE_TYPE_PER_GALLON',
        then: Yup.number().required(),
      }),
      percentAmount: Yup.number().when('type', {
        is: (type: string) => type === 'FEE_TYPE_PERCENT_AMOUNT',
        then: Yup.number().required(),
      }),
    }),
  ),
});

export const ProgramMarketingFees = () => {
  const [asOfDate, setAsOfDate] = useState(new Date());
  const [selectedMerchants, setSelectedMerchants] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const programUuid = getProgramUuid();

  const {
    data: merchantsData,
    // loading,
    // error,
  } = useQuery<{
    getMerchants: types.MerchantType[];
  }>(queries.QUERY_GET_MERCHANTS);

  const merchants = merchantsData?.getMerchants;

  const handleOnClose = () => {
    navigate(PathPrivate.Carriers);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!programUuid) return;

    const getData = async () => {
      await retrieveProgram(programUuid).then((response: any) => {
        if (response.isAxiosError) {
          throw Error(response.response.data.message);
        } else {
          return;
        }
      });
    };
    getData();
  }, [programUuid]);

  const [fetchFees, { data: feeData }] = useLazyQuery(
    queriesCarriers.GET_FEES,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    const uniqueMerchants = Array.from(
      new Set(
        feeData?.getFees
          .map((item: { merchantUuid: string }) => {
            if (item.merchantUuid) {
              return item.merchantUuid;
            }
            return null;
          })
          .filter(Boolean), // Remove any null values from the array
      ),
    ) as string[];

    setSelectedMerchants(uniqueMerchants);
  }, [feeData]);

  useEffect(() => {
    if (programUuid) {
      fetchFees({ variables: { programUuid: programUuid } });
    }
  }, [programUuid, fetchFees]);

  const existingFees = useMemo(() => {
    if (feeData) {
      const fees = feeData.getFees;
      const parsedFees = fees.map((fee: any) => {
        return {
          ...fee,
        };
      });
      return parsedFees;
    }
    return;
  }, [feeData]);

  const getAdornment = (feeType: string) => {
    let startAdornmentElement = null;
    if (feeType === 'FEE_TYPE_PER_GALLON') {
      startAdornmentElement = (
        <InputAdornment position="start">$</InputAdornment>
      );
    }

    if (feeType === 'FEE_TYPE_PERCENT_AMOUNT') {
      startAdornmentElement = (
        <InputAdornment position="start">%</InputAdornment>
      );
    }

    return startAdornmentElement;
  };

  return (
    <Dialog open onClose={handleOnClose} fullWidth>
      <DialogTitle>Program Marketing Fees</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={
            !isEmpty(existingFees)
              ? {
                  fees: existingFees,
                }
              : {
                  fees: [
                    {
                      programUuid: '',
                      merchantUuid: '',
                      carrierUuid: '',
                      feeCategory: FEE_CATEGORY,
                      type: '',
                      perGallonAmount: '',
                    },
                  ],
                }
          }
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values) => {
            const fees = values?.fees?.map((fee: any) => {
              const payload = {
                merchantUuid: fee.merchantUuid,
                programUuid: programUuid,
                type: fee.type,
                billingType: 'BILLING_TYPE_FUNDED',
                productCategory: 'PRODUCT_CATEGORY_DIESEL',
                feeCategory: FEE_CATEGORY,
                asOfDate: format(asOfDate, 'yyyyMMdd'),
                perGallonAmount: fee.perGallonAmount,
                percentAmount: fee.percentAmount,
              };

              if (fee.type === 'FEE_TYPE_FLAT_AMOUNT') {
                payload.percentAmount = '0';
                payload.perGallonAmount = '0';
              } else if (fee.type === 'FEE_TYPE_PERCENT_AMOUNT') {
                payload.perGallonAmount = '0';
              } else if (fee.type === 'FEE_TYPE_PER_GALLON') {
                payload.percentAmount = '0';
              }

              return { ...payload };
            });
            const payload = { fees, program_uuid: programUuid };
            setProgramFeesService(payload)
              .then(() => {
                toast.success('Marketing Fees set');
                // getCreateProgramService().then((response: any) => {
                // });
                handleOnClose();
              })
              .catch((e: any) => {
                if (e.response) {
                  toast.error(e.response.data.message);
                } else {
                  toast.error(e.message);
                }
              });
          }}
        >
          {({ values, handleChange, handleSubmit, dirty }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {dirty ? (
                  <Alert severity="warning">
                    Save changes before switching to a different section.
                    Changes will be lost if unsaved.
                  </Alert>
                ) : null}
                <FormGroup row sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Effective Date"
                      value={asOfDate}
                      minDate={new Date()}
                      onChange={(newValue: any) => setAsOfDate(newValue)}
                      renderInput={(params) => (
                        <TextField name="asOfDate" fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </FormGroup>
                <FieldArray
                  name="fees"
                  render={(arrayHelpers) =>
                    values.fees?.map(
                      (
                        fee: {
                          merchantUuid: any;
                          feeCategory: any;
                          type: string;
                          carrierUuid: any;
                          perGallonAmount: unknown;
                          percentAmount: unknown;
                        },
                        index: any,
                      ) => {
                        return (
                          <Box key={`${fee.merchantUuid}-${index}`}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              mt={2}
                            >
                              <FormControl sx={{ mr: 2, minWidth: 150 }}>
                                <InputLabel>Type</InputLabel>
                                <Select
                                  fullWidth
                                  name={`fees[${index}].type`}
                                  value={fee.type}
                                  onChange={handleChange}
                                  input={<OutlinedInput label="Type" />}
                                >
                                  <MenuItem
                                    key={'perGallon'}
                                    value="FEE_TYPE_PER_GALLON"
                                  >
                                    Per Gallon
                                  </MenuItem>
                                  <MenuItem
                                    key={'percent'}
                                    value="FEE_TYPE_PERCENT_AMOUNT"
                                  >
                                    Percentage
                                  </MenuItem>
                                </Select>
                                <ErrorMessage name={`fees[${index}].type`} />
                              </FormControl>
                              <FormControl
                                fullWidth
                                sx={{ mr: 2, minWidth: 200 }}
                              >
                                <InputLabel>Merchant</InputLabel>
                                <Select
                                  fullWidth
                                  name={`fees[${index}].merchantUuid`}
                                  value={fee.merchantUuid}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSelectedMerchants([
                                      ...selectedMerchants,
                                      e.target.value,
                                    ]);
                                  }}
                                  input={<OutlinedInput label="Merchant" />}
                                >
                                  {merchants?.map((m) => {
                                    const disabled = selectedMerchants.includes(
                                      m.uuid,
                                    );
                                    return (
                                      <MenuItem
                                        key={m.name}
                                        value={m.uuid}
                                        disabled={disabled}
                                      >
                                        {m.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <ErrorMessage
                                  name={`fees[${index}].carrierUuid`}
                                />
                              </FormControl>
                              {values.fees[index].type ===
                              'FEE_TYPE_PERCENT_AMOUNT' ? (
                                <FormControl fullWidth>
                                  <TextField
                                    name={`fees.${index}.percentAmount`}
                                    label="Percent Amount"
                                    value={fee.percentAmount}
                                    onChange={handleChange}
                                    InputProps={{
                                      startAdornment: getAdornment(fee.type),
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`fees[${index}].percentAmount`}
                                  />
                                </FormControl>
                              ) : (
                                <FormControl fullWidth>
                                  <TextField
                                    name={`fees.${index}.perGallonAmount`}
                                    label="Per Gallon Amount"
                                    value={fee.perGallonAmount}
                                    onChange={handleChange}
                                    InputProps={{
                                      startAdornment: getAdornment(fee.type),
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`fees[${index}].perGallonAmount`}
                                  />
                                </FormControl>
                              )}
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography
                                  onMouseEnter={(e) => {
                                    merchants?.length === values.fees?.length
                                      ? handlePopoverOpen(e)
                                      : null;
                                  }}
                                  onMouseLeave={() =>
                                    merchants?.length === values.fees?.length
                                      ? handlePopoverClose()
                                      : null
                                  }
                                >
                                  <IconButton
                                    onClick={() =>
                                      arrayHelpers.push({
                                        // TODO: Replace with InitialValues object
                                        feeCategory: FEE_CATEGORY,
                                        type: '',
                                        merchantUuid: '',
                                        amount: '',
                                      })
                                    }
                                    disabled={
                                      merchants?.length === values.fees?.length
                                    }
                                  >
                                    <AddCircleOutlineIcon />
                                  </IconButton>
                                </Typography>
                                <Popover
                                  sx={{
                                    pointerEvents: 'none',
                                  }}
                                  open={popoverOpen}
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  onClose={handlePopoverClose}
                                  disableRestoreFocus
                                >
                                  <Typography sx={{ p: 1 }}>
                                    Max number of merchant entries reached.
                                  </Typography>
                                </Popover>
                              </Box>
                            </Box>
                          </Box>
                        );
                      },
                    )
                  }
                />
                <DialogActions>
                  <SecondaryButton onClick={handleOnClose}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton type="submit" disabled={!dirty}>
                    Save
                  </PrimaryButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
