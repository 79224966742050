import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCarrier } from '.';
import { listMerchantLocationsServiceByCarrier } from '../services/shared';

type Address = {
  street1: string;
  street2: string;
  city: string;
  region: string;
  postalCode: string;
};

interface MerchantLocation {
  address: Address;
  uuid: string;
  name: string;
  lat: string;
  long: string;
  tid: string;
  numberOfDispensers: number;
  dispenserNumbers: number[];
  externalId: string;
  orLocationId: string;
  phone: string;
  storeNumber: string;
  active: boolean;
  acceptedPaymentTypes: string[];
  opisRackMapperId: string;
  postalCode: string;
  insideOnly: boolean;
  activeDate: string;
  deactivatedDate: string;
  retailPrice: string;
  discountedPrice: string;
  insidePaymentTypes: string[];
  outsidePaymentTypes: string[];
}

const useMerchantLocations = () => {
  const { carrierId: carrierUuid } = useParams();
  const [reload, setReload] = useState(false);

  const carrier = useCarrier();

  const programUuid = carrier?.program?.uuid;

  const [merchantLocations, setMerchantLocations] = useState<
    MerchantLocation[]
  >([
    {
      uuid: '',
      name: '',
      lat: '',
      long: '',
      tid: '',
      dispenserNumbers: [],
      numberOfDispensers: 0,
      externalId: '',
      address: {
        street1: '',
        street2: '',
        city: '',
        region: '',
        postalCode: '',
      },
      orLocationId: '',
      phone: '',
      storeNumber: '',
      active: false,
      acceptedPaymentTypes: [],
      opisRackMapperId: '',
      postalCode: '',
      insideOnly: false,
      activeDate: '',
      deactivatedDate: '',
      retailPrice: '',
      discountedPrice: '',
      insidePaymentTypes: [],
      outsidePaymentTypes: [],
    },
  ]);

  const shouldReload = reload === true;
  useEffect(() => {
    const getData = async () => {
      await listMerchantLocationsServiceByCarrier(
        carrierUuid || '',
        programUuid,
      ).then((response: any) => {
        if (response.isAxiosError) {
          throw Error(response.response.data.message);
        } else {
          const uniqueLocations = response.data.locations.filter(
            (loc: any, index: number, self: any[]) =>
              index === self.findIndex((t) => t.uuid === loc.uuid),
          );
          uniqueLocations.sort((a: any, b: any) =>
            a.name.localeCompare(b.name),
          );

          setMerchantLocations(uniqueLocations);
        }
      });
    };
    getData();
    if (shouldReload) setReload(false);
  }, [shouldReload, programUuid]);

  return { merchantLocations, setReload, reload };
};

export default useMerchantLocations;
