import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { getCarrierPaymentHistory } from './queries';
import * as auth from '@/aggregator/config/auth';

const columns: MUIDataTableColumnDef[] = [
  {
    label: 'ID',
    name: 'uuid',
    options: {
      filter: false,
      customBodyRender: (value: string) => {
        const lastSix = value.slice(-6).toUpperCase();
        return lastSix;
      },
    },
  },
  {
    label: 'Date',
    name: 'date',
    options: {
      filter: false,
      customBodyRender: (value: string) => {
        const date = new Date(value);
        const formattedDate = format(date, 'MM/dd/yyyy');

        return formattedDate;
      },
    },
  },
  {
    label: 'Payment Amount',
    name: 'amount',
    options: {
      filter: false,
      customBodyRender: (value: string) => `$${value}`,
    },
  },
  {
    label: 'Payer Email',
    name: 'payerEmail',
    options: {
      customBodyRender: (value: string) => (value ? value : '-'),
    },
  },
];

export const CarrierPaymentHistory = () => {
  const [loading, setLoading] = useState(true);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const { carrierId } = useParams();
  const programUuid = auth.getProgramUuid();

  useEffect(() => {
    if (carrierId && programUuid) {
      getCarrierPaymentHistory(programUuid, carrierId)
        .then((res) => setPaymentHistory(res.data.payments))
        .finally(() => setLoading(false));
    }
  }, [carrierId, programUuid]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <MUIDataTable
        columns={columns}
        data={paymentHistory}
        title="Payment History"
        options={{
          enableNestedDataAccess: '.',
          selectableRowsHideCheckboxes: true,
          filter: true,
          download: false,
          print: false,
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 20, 50],

          textLabels: {
            body: {
              noMatch: loading ? (
                <CircularProgress
                  sx={{
                    color: '#181818',
                  }}
                />
              ) : (
                'No payments found'
              ),
            },
          },
        }}
      />
    </div>
  );
};
