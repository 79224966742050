import axios from 'axios';
import { useEffect, useState } from 'react';
import { Config } from '@/aggregator/config/env';

export interface IDriver {
  attributes: any;
  carrierExternalId: string;
  carrierName: string;
  carrierProgramExternalId: string;
  carrierPublicId: string;
  carrierUuid: string;
  driverExternalId: string;
  email: string;
  enabled: boolean | string;
  endDate: string;
  firstName: string;
  identityUuid: string;
  lastName: string;
  phoneNumber: string;
  startDate: string;
  type: string;
  uuid: string;
}

const emptyDriver: IDriver = {
  attributes: {},
  carrierExternalId: '',
  carrierName: '',
  carrierProgramExternalId: '',
  carrierPublicId: '',
  carrierUuid: '',
  driverExternalId: '',
  email: '',
  enabled: false,
  endDate: '',
  firstName: '',
  identityUuid: '',
  lastName: '',
  phoneNumber: '',
  startDate: '',
  type: '',
  uuid: '',
};

function useGetDrivers(carrierUuid?: string) {
  const [drivers, setDrivers] = useState<IDriver[]>([emptyDriver]);
  const [reload, setReload] = useState(false);

  const shouldReload = reload === true;
  useEffect(() => {
    const getData = async () => {
      const fetchUrl = `${Config.CARRIER_API_URL}/carriers/${carrierUuid}/drivers`;

      await axios(fetchUrl).then((response: any) => {
        if (response.isAxiosError) {
          throw Error(response.response.data.message);
        } else {
          setDrivers(response.data.drivers);
        }
      });
    };
    getData();

    if (shouldReload) setReload(false);
  }, [shouldReload]);

  return { drivers, setReload };
}

export default useGetDrivers;
