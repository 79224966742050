import { useEffect, useState } from 'react';
import { listMerchantsService } from '../services/shared';

interface IProcessingFeePercent {
  value: string;
}
interface IMerchantFee {
  merchant_uuid: string;
  as_of_date: string;
  flat_amount: string;
  percent_amount: string;
  type: string;
  billing_type: string;
  uuid: string;
}

interface IExternalAccount {
  uuid: string;
  provider: string;
  createdAt: string;
}

interface IMerchant {
  uuid: string;
  mapMarkerUrl: string;
  chainName: string;
  processingFeePercent: IProcessingFeePercent; // deprecated
  tokenMessage: string;
  fees: IMerchantFee[];
  externalAccounts: IExternalAccount[];
}

function useMerchants() {
  const [merchants, setMerchants] = useState<IMerchant[]>([]);
  const [reload, setReload] = useState(false);

  const shouldReload = reload === true;

  useEffect(() => {
    const getData = async () => {
      await listMerchantsService().then((response: any) => {
        if (response.isAxiosError) {
          throw Error(response.response.data.message);
        } else {
          setMerchants(response.data.merchants);
        }
      });
    };
    getData();
    if (shouldReload) setReload(false);
  }, [shouldReload]);

  return { merchants, setReload };
}

export default useMerchants;
