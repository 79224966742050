import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Mixpanel } from '@/aggregator/config/mixpanel';

const ScreenTracker = () => {
  const location = useLocation();

  const parseLocation = (path: string) => {
    return path.replace(/^\//, '');
  };

  useEffect(() => {
    Mixpanel.track('page_viewed', {
      page: parseLocation(location.pathname),
    });
  }, [location]);

  return null;
};

export default ScreenTracker;
