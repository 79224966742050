import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';
import { SecondaryButton, PrimaryButton } from '@/aggregator/ui/inputs/Button';

interface Iprops {
  action: 'cancel' | 'dispatch'; // Restrict action to allowed keys
  handleClose: () => void;
  handleDispatch: () => void;
  handleCancel: () => void;
  task: { id: string; url: string };
}

const ACTION_TITLES: Record<'cancel' | 'dispatch', string> = {
  cancel: 'Are you sure you want to DELETE this report?',
  dispatch: 'Are you sure you want to RUN this report?',
};

export const ActionModal: FC<Iprops> = ({
  action,
  handleClose,
  handleDispatch,
  handleCancel,
}) => {
  const title = ACTION_TITLES[action];

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle m={1}>{title}</DialogTitle>
      <DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={action === 'cancel' ? handleCancel : handleDispatch}
          >
            Confirm
          </PrimaryButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
