import axios from 'axios';
import { Config } from '@/aggregator/config/env';

const apiUrl = Config.CARRIER_API_URL;

export async function getCarrierPaymentHistory(
  programUuid: string,
  carrierUuid: string,
) {
  return await axios
    .get(
      `${apiUrl}/programs/${programUuid}/payments?carrier_uuid=${carrierUuid}`,
    )
    .then((response) => response)
    .catch((error) => error);
}
