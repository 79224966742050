import { usePapaParse } from 'react-papaparse';
import {
  getTransactionsService,
  getTransactionsServiceV2,
} from '../../queries';
import { get } from 'lodash';
import { EXPORT_FILE_TYPES } from '@/aggregator/constants';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { Config } from '@/aggregator/config/env';
import axios from 'axios';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';

type TransactionRecord = {
  driverName?: string;
  quantity?: { value: string };
  unitRetailCost?: { value: string };
  unitDiscountedCost?: { value: string };
  grandTotal?: string;
  transactionNumber?: string;
  [key: string]: any;
};

type GroupedData = {
  [driverName: string]: TransactionRecord[];
};

type Totals = {
  'Driver Grand Total': number;
  'Total Savings': number;
  'Diesel Gallons': number;
  'Diesel Total Dollars': number;
  'Reefer Gallons': number;
  'Reefer Total Dollars': number;
  'DEF Gallons': number;
  'DEF Total Dollars': number;
  'Cash Advance Total': number;
  'Motor Oil Total Dollars': number;
};

type Column = {
  dataField: string;
  text: string;
};

const exportTransactionColumns = [
  { dataField: 'date', text: 'Date' },
  { dataField: 'time', text: 'Time' },
  { dataField: 'transactionNumber', text: 'Transaction Number' },
  { dataField: 'merchantId', text: 'Merchant Id' },
  { dataField: 'truckStopName', text: 'Truckstop Name' },
  { dataField: 'storeNumber', text: 'Store Number' },
  { dataField: 'locationId', text: 'Location ID' },
  { dataField: 'city', text: 'City' },
  { dataField: 'region', text: 'Region' },
  { dataField: 'productName', text: 'Product Name' },
  { dataField: 'productCode', text: 'Product Code' },
  { dataField: 'quantity.value', text: 'Quantity' },
  { dataField: 'unitRetailCost.value', text: 'Unit Retail Cost' },
  { dataField: 'unitDiscountedCost.value', text: 'Unit Discounted Cost' },
  { dataField: 'tax', text: 'Tax' },
  { dataField: 'carrierFee', text: 'Fee Amount' },
  { dataField: 'productRetailTotal.value', text: 'Product Retail Cost' },
  {
    dataField: 'productDiscountedTotal.value',
    text: 'Product Discounted Total',
  },
  { dataField: 'discountTotal.value', text: 'Discount Total' },
  { dataField: 'grandTotal', text: 'Grand Total' },
  { dataField: 'unitNumber', text: 'Unit Number' },
  { dataField: 'trailerNumber', text: 'Trailer Number' },
  { dataField: 'driverNumber', text: 'Driver Number' },
  { dataField: 'driverName', text: 'Driver Name' },
  { dataField: 'authorizationNumber', text: 'Auth Number' },
  { dataField: 'inNetworkIndicator', text: 'In Network Indicator' },
  { dataField: 'division', text: 'Division' },
  { dataField: 'tripNumber', text: 'Trip Number' },
  { dataField: 'odometer', text: 'Odometer' },
  { dataField: 'pumpNumber', text: 'Pump Number' },
  { dataField: 'billingTypeIndicator', text: 'Funding Type' },
];

const dataMinimal = [
  { dataField: 'date', text: 'Date' },
  { dataField: 'unitNumber', text: 'Truck#' },
  { dataField: 'trailerNumber', text: 'Trailer#' },
  { dataField: 'driverName', text: 'Driver Name' },
  { dataField: 'driverNumber', text: 'Driver ID' },
  { dataField: 'city', text: 'City' },
  { dataField: 'region', text: 'State' },
  { dataField: 'productName', text: 'Product' },
  { dataField: 'unitRetailCost.value', text: 'Retail Price' },
  { dataField: 'unitDiscountedCost.value', text: 'Discounted Price' },
  { dataField: 'savings', text: 'Savings' }, // (calculated on frontend)
  { dataField: 'grandTotal', text: 'Final Total' },
  { dataField: 'transactionNumber', text: 'Transaction Number' },
];

export const convertTimeToTz = (time: any) => {
  const tz = time?.getTimezoneOffset(); //get user timezone offset
  const utcTime = time?.setTime(
    //create utc time from timezone offset
    time.getTime() - tz * 60 * 1000,
  );

  const utcDate = new Date(utcTime); //create utc date object

  const tzTime = utcDate?.setTime(
    utcDate.getTime() + 5 * 60 * 60 * 1000, //create time from utcTime with 5 hours added for EST
  );

  const newTime = new Date(tzTime); //create date object from time

  return newTime;
};

type ExportDataProps = {
  data: TransactionRecord[];
  columns: Column[];
  fileName: string;
  omitHeaderRow?: boolean;
};

export const ExportDataAsCSV = ({
  data,
  columns,
  fileName,
  omitHeaderRow = false,
}: ExportDataProps): void => {
  const { jsonToCSV } = usePapaParse();

  const exportableData = data.map((line: any) => {
    const newLine: any = {};
    columns.map(
      (column: Column) => (newLine[column.text] = get(line, column.dataField)),
    );
    return newLine;
  });

  const csvContent = jsonToCSV(exportableData, {
    header: !omitHeaderRow,
  });

  const csvData = new Blob([`${csvContent}`], {
    type: 'text/csv;charset=utf-8;',
  });

  const csvURL = window.URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL as string;
  link.setAttribute('download', `${fileName}.csv`);
  link.click();
  link.remove();
};

const calculateTotals = (data: TransactionRecord[]): Totals => {
  const totals: Totals = {
    'Driver Grand Total': 0,
    'Total Savings': 0,
    'Diesel Gallons': 0,
    'Diesel Total Dollars': 0,
    'Reefer Gallons': 0,
    'Reefer Total Dollars': 0,
    'DEF Gallons': 0,
    'DEF Total Dollars': 0,
    'Cash Advance Total': 0,
    'Motor Oil Total Dollars': 0,
  };

  data.forEach((record) => {
    const quantity = parseFloat(record?.quantity?.value || '0');
    const unitRetailCost = parseFloat(record?.unitRetailCost?.value || '0');
    const unitDiscountedCost = parseFloat(
      record?.unitDiscountedCost?.value || '0',
    );
    const total = parseFloat(record?.grandTotal || '0');

    const savings =
      !isNaN(quantity) && !isNaN(unitRetailCost) && !isNaN(unitDiscountedCost)
        ? (unitRetailCost - unitDiscountedCost) * quantity
        : 0;

    if (!isNaN(savings)) totals['Total Savings'] += savings;
    if (!isNaN(total)) totals['Driver Grand Total'] += total;

    if (record?.productName && typeof record.productName === 'string') {
      const productNameLower = record.productName.toLowerCase();
      if (
        productNameLower.includes('diesel') &&
        !productNameLower.includes('exhaust')
      ) {
        if (!isNaN(quantity)) totals['Diesel Gallons'] += quantity;
        if (!isNaN(total)) totals['Diesel Total Dollars'] += total;
      } else if (productNameLower.includes('reefer')) {
        if (!isNaN(quantity)) totals['Reefer Gallons'] += quantity;
        if (!isNaN(total)) totals['Reefer Total Dollars'] += total;
      } else if (
        productNameLower.includes('def') ||
        productNameLower.includes('exhaust')
      ) {
        if (!isNaN(quantity)) totals['DEF Gallons'] += quantity;
        if (!isNaN(total)) totals['DEF Total Dollars'] += total;
      } else if (productNameLower.includes('cash advance')) {
        if (!isNaN(total)) totals['Cash Advance Total'] += total;
      } else if (productNameLower.includes('motor oil')) {
        if (!isNaN(total)) totals['Motor Oil Total Dollars'] += total;
      }
    }
  });

  return totals;
};

const addTotalBoxes = (doc: jsPDF, totals: Totals) => {
  const totalRows = Object.entries(totals)
    .filter(([, value]) => value > 0)
    .map(([key, value]) => [key, value.toFixed(2)]);

  const totalBoxWidth = 300;
  const totalBoxHeight = (totalRows.length + 1) * 20 + 10;
  const startY = (doc as any).lastAutoTable.finalY + 20;
  const startX = (doc.internal.pageSize.width - totalBoxWidth) / 2;

  const pageHeight = doc.internal.pageSize.height;
  const logoHeight = 60; // Define logoHeight here

  if (startY + totalBoxHeight > pageHeight) {
    doc.addPage();

    autoTable(doc, {
      head: [['Item', 'Value']],
      body: totalRows,
      startY: logoHeight + 20,
      theme: 'striped',
      styles: {
        fontSize: 10,
        cellPadding: 5,
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: '#181818',
        textColor: '#FFFFFF',
        fontSize: 10,
      },
      tableWidth: totalBoxWidth,
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'left' },
        1: { cellWidth: 'auto', halign: 'right' },
      },
      didDrawPage: () => {
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.rect(startX, logoHeight + 20, totalBoxWidth, totalBoxHeight);
      },
      margin: { left: startX },
    });
  } else {
    autoTable(doc, {
      head: [['Item', 'Value']],
      body: totalRows,
      startY: startY,
      theme: 'striped',
      styles: {
        fontSize: 10,
        cellPadding: 5,
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: '#181818',
        textColor: '#FFFFFF',
        overflow: 'linebreak',
        fontSize: 10,
      },
      tableWidth: totalBoxWidth,
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'left' },
        1: { cellWidth: 'auto', halign: 'right' },
      },
      didDrawPage: () => {
        // Draw box around totals
        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.rect(startX, startY, totalBoxWidth, totalBoxHeight);
      },
      margin: { left: startX },
    });
  }
};

const groupDataByDriver = (data: TransactionRecord[]): GroupedData => {
  return data.reduce((acc: GroupedData, record) => {
    const driverName = record.driverName || 'Unknown Driver';
    if (!acc[driverName]) {
      acc[driverName] = [];
    }
    acc[driverName].push(record);
    return acc;
  }, {});
};

export const exportDataAsPDF = ({
  data,
  columns,
  fileName,
}: ExportDataProps) => {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'a4',
  });

  const logoHeight = 60;
  const columnsPerSection = 16; // Reduced number of columns per section to allow wider columns
  const margins = { left: 20, right: 20 };

  Object.entries(groupDataByDriver(data)).forEach(([, driverData], index) => {
    if (index > 0) {
      doc.addPage();
    }

    doc.setFontSize(16);
    doc.text(`Transaction Export`, margins.left, logoHeight + 20);

    // Split columns into sections
    const columnSections = [];
    for (let i = 0; i < columns.length; i += columnsPerSection) {
      columnSections.push(columns.slice(i, i + columnsPerSection));
    }

    let startY = logoHeight + 40;

    // Create a table for each section of columns
    columnSections.forEach((columnSection, sectionIndex) => {
      const sectionRows = driverData.map((record) =>
        columnSection.map((col: Column) => {
          const value = get(record, col.dataField, '');

          if (col.dataField === 'transactionNumber') {
            return value !== undefined && value !== null
              ? value.toString().replace(/^0+/, '')
              : '';
          }

          return value !== undefined && value !== null ? value.toString() : '';
        }),
      );

      if (sectionIndex > 0) {
        const previousTable = (doc as any).lastAutoTable;
        startY = previousTable.finalY + 30;

        if (startY + 100 > doc.internal.pageSize.height) {
          doc.addPage();
          startY = logoHeight + 40;
          doc.setFontSize(16);
        }
      }

      autoTable(doc, {
        head: [columnSection.map((col) => col.text)],
        body: sectionRows,
        startY: startY,
        margin: margins,
        theme: 'striped',
        headStyles: {
          fillColor: '#181818',
          textColor: '#FFFFFF',
          fontSize: 10,
        },
        bodyStyles: { valign: 'middle', fontSize: 9 },
        styles: {
          overflow: 'linebreak',
          cellWidth: 'auto',
          minCellHeight: 15,
        },
      });
    });

    const lastTable = (doc as any).lastAutoTable;
    const totalsStartY = lastTable.finalY + 30;

    if (totalsStartY + 200 > doc.internal.pageSize.height) {
      doc.addPage();
      const totals = calculateTotals(driverData);
      addTotalBoxes(doc, totals);
    } else {
      const totals = calculateTotals(driverData);
      addTotalBoxes(doc, totals);
    }
  });

  doc.save(fileName);
};

export const exportTransactions = async (
  carrierUuid: string,
  startDate: Date | null,
  endDate: Date | null,
  fileType: string = EXPORT_FILE_TYPES.PDF.value,
) => {
  const dupeStartDate = startDate ? new Date(startDate) : null;
  const dupeEndDate = endDate ? new Date(endDate) : null;
  // const reportDate = format(new Date(), 'MM-dd-yyyy');

  const sDate =
    dupeStartDate !== null ? convertTimeToTz(dupeStartDate).toISOString() : '';
  const eDate =
    dupeEndDate !== null ? convertTimeToTz(dupeEndDate).toISOString() : '';

  const fileStartDate = format(new Date(sDate), 'MM-dd-yyyy');
  const fileEndDate = format(new Date(eDate), 'MM-dd-yyyy');

  const reportDate = format(new Date(), 'MM-dd-yyyy');

  if (fileType === EXPORT_FILE_TYPES.PDF.value) {
    getTransactionsServiceV2(carrierUuid, sDate, eDate, fileType).then(
      async (res) => {
        const data = res.data; // Axios response
        const rawBytes = Uint8Array.from(atob(data.raw), (c) =>
          c.charCodeAt(0),
        );
        const blob = new Blob([rawBytes], { type: fileType });

        const fileName = Object.values(EXPORT_FILE_TYPES).find(
          (type) => type.value === fileType,
        )?.name;

        // Create URL and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `report.${fileName}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
    );
    return;
  }

  getTransactionsService(carrierUuid, sDate, eDate).then((response) => {
    if (fileType === EXPORT_FILE_TYPES.CSV.value) {
      ExportDataAsCSV({
        data: response.data.transactionRecords,
        columns: exportTransactionColumns,
        fileName: `Onramp-${reportDate}-transactions-report`,
      });
    }

    if (fileType === EXPORT_FILE_TYPES.XLS.value) {
      const filteredTransactionRecords = response.data.transactionRecords.map(
        (record: any) => {
          const filteredRecord: any = {};
          dataMinimal.forEach((col) => {
            const keys = col.dataField.split('.');
            if (keys.length > 1) {
              filteredRecord[col.text] = keys.reduce(
                (acc, key) => acc && acc[key],
                record,
              );
            } else {
              filteredRecord[col.text] = record[col.dataField];
            }
          });
          return filteredRecord;
        },
      );
      const worksheet = XLSX.utils.json_to_sheet(filteredTransactionRecords, {
        header: dataMinimal.map((col) => col.text),
      });

      const workbook = XLSX.utils.book_new();
      worksheet['!cols'] = dataMinimal.map(() => ({ wch: 20 }));

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(
        workbook,
        `${fileStartDate}-${fileEndDate}-${fileType}-report.xlsx`,
      );
    }
  });
};

export const getReportData = async ({
  carrierUuid,
  fileType,
  formatType,
  startDate,
  endDate,
}: {
  carrierUuid: string;
  fileType: string;
  formatType?: string | undefined;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  return await axios
    .get(
      `${Config.REPORT_API_URL}/report/carriers/${carrierUuid}?fileType=${fileType}&formatType=${formatType}&startDate=${startDate}&endDate=${endDate}`,
    )
    .then((response) => response);
};
