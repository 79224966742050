import * as React from 'react';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import { useApolloClient } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
} from '@mui/material';
import { PathPrivate } from '@/aggregator/paths/constants';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import { SecondaryButton, PrimaryButton } from '@/aggregator/ui/inputs/Button';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import * as helpers from './helpers';
import * as queries from './queries';
import * as mutations from './mutations';
import { getUserEmail } from '@/aggregator/config/auth';

export function CarrierBookPayment() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { carrierId = '' } = useParams();
  const email = getUserEmail();

  const [loading, setLoading] = React.useState(true);
  const [balance, setBalance] = React.useState({
    balanceAmount: null,
    availableFunds: null,
    balances: null,
  });

  React.useEffect(() => {
    const getData = async () => {
      await queries.getCarrierBalance(carrierId).then((response: any) => {
        if (response.isAxiosError) {
          throw Error(response.response.data.message);
        } else {
          setBalance(response.data);
        }
        setLoading(false);
      });
    };
    getData();
  }, []);

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  if (!carrier) return <div>Carrier not found</div>;
  if (loading) return <CircularProgress />;

  const handleOnClose = () => {
    navigate(PathPrivate.Carriers);
  };

  return (
    <Formik
      initialValues={{
        amount: '',
        initiationDate: new Date(),
        payerUuid: carrier.uuid,
      }}
      validationSchema={helpers.validationSchemaObject}
      onSubmit={(values) => {
        const parsedValues = {
          ...values,
          ...{
            initiationDate: format(values.initiationDate, 'yyyyMMdd'),
            amount: values.amount.toString(),
            payerUuid: carrier.uuid,
            payerEmail: email || '',
          },
        };

        mutations
          .updatePayments(carrierId, parsedValues)
          .then((response: any) => {
            if (response.isAxiosError) {
              throw Error(response.message);
            } else {
              toast.success('Payment booked');
              handleOnClose();
            }
          })
          .catch((error: any) =>
            toast.error(
              `Failed to book payment\n Error detail: ${error.message}`,
            ),
          );
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Dialog open onClose={handleOnClose} scroll="paper">
          <DialogTitle sx={{ textAlign: 'center' }}>
            Carrier: {carrier.name}
          </DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box
                sx={{
                  mx: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h6" component="h2" mb={2}>
                  Book Payment
                </Typography>
                <Alert severity="warning" sx={{ mb: 2 }}>
                  {`The payment booked will imediatelly affect the available funds for ${carrier.name}.`}
                </Alert>
                <FormControl className="mb-3">
                  <TextField
                    label="Payment Amount"
                    variant="outlined"
                    fullWidth
                    name="amount"
                    value={values.amount}
                    error={!!errors.amount}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl className="mb-3">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled
                      label="Payment date"
                      value={values.initiationDate}
                      onChange={() => {}}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            name="initiationDate"
                            inputProps={params.inputProps}
                            disabled={params.disabled}
                            error={params.error}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Balance"
                    variant="outlined"
                    value={balance.balanceAmount}
                    fullWidth
                    disabled
                  />
                </FormControl>
                <FormControl className="mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Available funds"
                    variant="outlined"
                    value={balance.availableFunds}
                    fullWidth
                    disabled
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleOnClose}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" onClick={handleSubmit}>
                Save
              </PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
