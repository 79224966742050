import * as React from 'react';
import { gql, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputLabel,
  Select,
  FormControl,
  Box,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Button,
  IconButton,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PathPrivate } from '@/aggregator/paths/constants';
import { flattenDeep, isEmpty } from 'lodash';
import { TimePicker } from '@mui/x-date-pickers';
import { useMemo, useState } from 'react';
import { cancelButtonStyle } from '../../../../../components/common/styles';
import {
  useCarrier,
  useGetDrivers,
  useMerchantLocations,
} from '../../../../../hooks/';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';

const START_TRANSACTION = gql`
  mutation StartTransaction(
    $timestamp: DateTime!
    $dispenser: Int!
    $locationUuid: String!
    $driverID: String!
    $extraData: TransactionExtraDataInput!
    $overrideLimits: [OverrideLimit]
    $extendedDate: DateTime
  ) {
    startTransaction(
      timestamp: $timestamp
      dispenser: $dispenser
      locationUuid: $locationUuid
      driverID: $driverID
      extraData: $extraData
      overrideLimits: $overrideLimits
      extendedDate: $extendedDate
    ) {
      id
      token
      status
    }
  }
`;

const initialValues = {
  driver: '',
  truckNumber: '',
  location: '',
  pump: null,
  productType: '',
  showFuelLimits: false,
  showNonFuelLimits: false,
  overrideLimits: [
    {
      category: null,
      amount: 0,
      quantity: 0,
    },
  ],
  nonFuelLimits: [
    {
      category: null,
      amount: 0,
      quantity: 0,
    },
  ],
  expirationDate: '',
};

const availableFuelProducts = [
  {
    label: 'Tractor Diesel',
    productCategory: 'Diesel',
  },
  {
    label: 'Reefer Diesel',
    productCategory: 'Reefer',
  },
  {
    label: 'DEF',
    productCategory: 'Def',
  },
  {
    label: 'Other Non Diesel Fuels',
    productCategory: 'NonDieselFuels',
  },
];

const availableNonFuelProducts = [
  {
    label: 'Oil',
    productCategory: 'Oil',
  },
  {
    label: 'Additives',
    productCategory: 'Additives',
  },
  {
    label: 'Merchandise',
    productCategory: 'Merchandise',
  },
  {
    label: 'Cash Advance',
    productCategory: 'CashAdvance',
  },
  {
    label: 'Services',
    productCategory: 'Services',
  },
  {
    label: 'Maintenance',
    productCategory: 'Maintenance',
  },
];

const validationSchemaObject = Yup.object().shape({
  expirationDate: Yup.string()
    .optional()
    .test(
      'expirationDate',
      'Expiration date must be within 48 hours from now',
      function (value) {
        if (!value) {
          // If expirationDate is not provided, it's considered valid
          return true;
        }

        const expirationDate = new Date(value);
        const minDate = new Date();
        const maxDate = new Date();
        maxDate.setHours(maxDate.getHours() + 48); // Adding 48 hours

        return expirationDate >= minDate && expirationDate <= maxDate;
      },
    ),
  driver: Yup.string().required('Required field'),
  location: Yup.string().required('Required field'),
  pump: Yup.string().nullable(),
  truckNumber: Yup.string().required('Required field'),
  showFuelLimits: Yup.boolean(),
  showNonFuelLimits: Yup.boolean(),
  productType: Yup.string().when('showFuelLimits', {
    is: (showFuelLimits: boolean) => showFuelLimits,
    then: Yup.string().required('Required field'),
    otherwise: Yup.string().optional(),
  }),
  overrideLimits: Yup.array().of(
    Yup.object().shape({
      category: Yup.string()
        .nullable()
        .when(['$productType'], (productType, schema) =>
          productType === 'Dollars' || productType === 'Gallons'
            ? schema.required('Required field').min(1)
            : schema.nullable(),
        ),
      amount: Yup.number()
        .nullable()
        .when(
          ['$productType'],
          (
            productType,
            schema, // Use $ to reference a sibling context value
          ) =>
            productType === 'Dollars'
              ? schema
                  .required('Required field')
                  .min(1, 'Must be greater than 0')
              : schema,
        )
        .max(1000, 'Max amount is $1,000'),
      quantity: Yup.number()
        .nullable()
        .when(
          ['$productType'],
          (
            productType,
            schema, // Use $ to reference a sibling context value
          ) =>
            productType === 'Gallons'
              ? schema
                  .required('Required field')
                  .min(1, 'Must be greater than 0')
              : schema,
        )
        .max(1000, 'Max gallons is 1000'),
    }),
  ),
  nonFuelLimits: Yup.array().of(
    Yup.object().shape({
      category: Yup.string()
        .nullable()
        .when(['$showNonFuelLimits'], (showNonFuelLimits, schema) =>
          showNonFuelLimits
            ? schema.required('Required field').min(1)
            : schema.nullable(),
        ),
      amount: Yup.number()
        .nullable()
        .when(['$showNonFuelLimits'], (showNonFuelLimits, schema) =>
          showNonFuelLimits
            ? schema.required('Required field').min(1, 'Must be greater than 0')
            : schema.nullable(),
        )
        .max(1000, 'Max amount is $1,000'),
    }),
  ),
});

interface FormValues {
  overrideLimits?: {
    category: string | null;
    amount: number;
    quantity: number;
  }[];
}

// Define the type guard to check if an object conforms to the FormValues interface
const isFormValues = (obj: any): obj is FormValues => {
  return obj?.overrideLimits !== undefined;
};

interface NonFuelFormValues {
  nonFuelLimits?: {
    category: string | null;
    amount: number;
    quantity: number;
  }[];
}

// Define the type guard to check if an object conforms to the FormValues interface
const isNonFuelFormValues = (obj: any): obj is NonFuelFormValues => {
  return obj?.nonFuelLimits !== undefined;
};

export function OneTimeLimit() {
  const { carrierId: carrierUuid } = useParams();
  const { drivers } = useGetDrivers(carrierUuid);
  const { name: carrierName } = useCarrier();

  const navigate = useNavigate();
  const { merchantLocations } = useMerchantLocations();
  const [selectedLocation, setSelectedLocation] = useState('');
  const [oneTimeToken, setOneTimeToken] = useState();
  const [showExpirationDate, setShowExpirationDate] = useState(false);
  const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);

  const [startTransaction] = useMutation(START_TRANSACTION);

  const driverOptions = useMemo(() => {
    return drivers;
  }, [drivers]);

  const selectedLocationPumps = useMemo(() => {
    const location = merchantLocations.find((ml) => {
      return ml.uuid === selectedLocation;
    });

    return isEmpty(location?.dispenserNumbers)
      ? location?.numberOfDispensers
      : location?.dispenserNumbers;
  }, [selectedLocation]);

  const isQuikQ = useMemo(() => {
    const paymentTypes = merchantLocations.find((ml) => {
      return ml.uuid === selectedLocation;
    })?.outsidePaymentTypes;

    return paymentTypes?.some((pt) => pt.includes('QUIKQ'));
  }, [selectedLocation]);

  const handleClose = (
    event: any,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (reason === 'backdropClick' && !!oneTimeToken) {
      return;
    }
    navigate(PathPrivate.Carriers);
  };

  const handleConfirmClose = () => {
    setShowConfirmCloseDialog(true);
  };

  const handleSubmit = async (values: any) => {
    try {
      let variables: any = {
        timestamp: new Date(),
        locationUuid: values.location,
        dispenser: values.pump || 0,
        driverID: values.driver,
        extraData: {
          trailerNumber: '', // optional
          tripNumber: '', // optional
          truckNumber: values.truckNumber,
          odometerReading: 0, // optional
        },
      };

      if (values.expirationDate) {
        variables = {
          ...variables,
          extendedDate: format(
            values.expirationDate,
            "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          ),
        };
      }

      if (
        values.overrideLimits[0].category !== null ||
        values.nonFuelLimits[0].category !== null
      ) {
        let combinedLimits;

        if (
          values.overrideLimits[0].category !== null &&
          values.nonFuelLimits[0].category === null
        ) {
          combinedLimits = values.overrideLimits;
        } else if (
          values.nonFuelLimits[0].category !== null &&
          values.overrideLimits[0].category === null
        ) {
          combinedLimits = values.nonFuelLimits;
        } else {
          combinedLimits = flattenDeep([
            values.overrideLimits,
            values.nonFuelLimits,
          ]);
        }

        variables = {
          ...variables,
          overrideLimits: combinedLimits,
        };
      }

      const { data: dataTransaction } = await startTransaction({
        variables: variables,
      });

      setOneTimeToken(dataTransaction.startTransaction.token);
    } catch (error) {
      toast.error(`${error}`);
      handleClose(undefined);
    }
  };

  const getPumps = ({ errors, values, touched, handleChange }: any) => {
    if (values.location && Array.isArray(selectedLocationPumps)) {
      return (
        <FormControl
          error={touched.pump && !!errors.pump}
          fullWidth
          sx={{ mb: 2 }}
        >
          <InputLabel>{`Pump # (optional)`}</InputLabel>
          <Select
            fullWidth
            name="pump"
            value={values.pump}
            onChange={handleChange}
            input={<OutlinedInput label="Pump # (optional)" />}
          >
            {selectedLocationPumps?.map((pump) => (
              <MenuItem key={`pump number ${pump}`} value={pump}>
                {pump}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={touched.pump && !!errors.pump}>
            {touched.pump && errors.pump}
          </FormHelperText>
        </FormControl>
      );
    }

    if (
      values.location &&
      !Array.isArray(selectedLocationPumps) &&
      selectedLocationPumps
    ) {
      const pumps = [];
      for (let i = 0; i < selectedLocationPumps; i++) {
        pumps.push(i + 1);
      }
      return (
        <FormControl
          error={touched.pump && !!errors.pump}
          fullWidth
          sx={{ mb: 2 }}
        >
          <InputLabel>{`Pump # (optional)`}</InputLabel>
          <Select
            fullWidth
            name="pump"
            value={values.pump}
            onChange={handleChange}
            input={<OutlinedInput label="Pump # (optional)" />}
          >
            {pumps?.map((pump) => (
              <MenuItem key={`pump number ${pump}`} value={pump}>
                {pump}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={touched.pump && !!errors.pump}>
            {touched.pump && errors.pump}
          </FormHelperText>
        </FormControl>
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaObject}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnSubmit={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <Dialog
          open
          onClose={(e, reason) => {
            resetForm();
            handleClose(e, reason);
          }}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle sx={{ textAlign: 'center' }}>
              Carrier: {carrierName}
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="h6" component="h2" mb={2}>
                One Time Limit
              </Typography>
              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: 400,
                    md: 500,
                  },
                  height: '100%',
                }}
              >
                <FormControl
                  error={touched.driver && !!errors.driver}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <InputLabel>Driver</InputLabel>
                  <Select
                    fullWidth
                    name="driver"
                    value={values.driver}
                    onChange={handleChange}
                    input={<OutlinedInput label="Driver" />}
                  >
                    {driverOptions?.map((driver) => (
                      <MenuItem key={driver.uuid} value={driver.uuid}>
                        {driver.firstName} {driver.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={touched.driver && !!errors.driver}>
                    {touched.driver && errors.driver}
                  </FormHelperText>
                </FormControl>

                <FormControl
                  error={touched.location && !!errors.location}
                  fullWidth
                  sx={{ mb: 2 }}
                >
                  <Autocomplete
                    options={merchantLocations}
                    getOptionLabel={(location) =>
                      `${location.name} #${location.storeNumber} - ${location.externalId} - ${location.address.city}, ${location.address.region}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        error={touched.location && !!errors.location}
                        variant="outlined"
                      />
                    )}
                    onChange={(event, value) => {
                      setSelectedLocation(value ? value.uuid : '');
                      setFieldValue('location', value ? value.uuid : '');
                    }}
                  />
                  <FormHelperText error={touched.location && !!errors.location}>
                    {touched.location && errors.location}
                  </FormHelperText>
                </FormControl>
                {getPumps({ errors, values, touched, handleChange })}
                {values.location && values.driver && (
                  <FormControl
                    error={touched.truckNumber && !!errors.truckNumber}
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    <TextField
                      label="Truck #"
                      name="truckNumber"
                      value={values.truckNumber}
                      onChange={handleChange}
                      error={touched.truckNumber && !!errors.truckNumber}
                      variant="outlined"
                    />
                    <FormHelperText
                      error={touched.truckNumber && !!errors.truckNumber}
                    >
                      {touched.truckNumber && errors.truckNumber}
                    </FormHelperText>
                  </FormControl>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Button
                    size="small"
                    color="secondary"
                    sx={[
                      cancelButtonStyle,
                      {
                        px: 4,
                        py: 3,
                        width: '100%',
                        textTransform: 'uppercase',
                      },
                    ]}
                    onClick={() => {
                      if (!values.showFuelLimits) {
                        setFieldValue('showFuelLimits', true);
                      } else {
                        setFieldValue('overrideLimits', [
                          {
                            category: null,
                            amount: 0,
                            quantity: 0,
                          },
                        ]);
                        setFieldValue('productType', '');
                        setFieldValue('showFuelLimits', false);
                      }
                    }}
                  >
                    {!values.showFuelLimits
                      ? 'Add Fuel Limits'
                      : 'Hide Fuel Limits'}
                  </Button>
                </Box>
                {values.showFuelLimits ? (
                  <>
                    <Typography mb={2} ml={1} fontSize={14} fontWeight={600}>
                      FUEL LIMITS
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{ mb: 2 }}
                      error={touched.productType && !!errors.productType}
                    >
                      <InputLabel>Product Type</InputLabel>
                      <Select
                        fullWidth
                        name="productType"
                        value={values.productType}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue('overrideLimits', [
                            {
                              category: null,
                              amount: 0,
                              quantity: 0,
                            },
                          ]);
                        }}
                        input={<OutlinedInput label="Product Type" />}
                      >
                        <MenuItem value="Gallons">Gallons</MenuItem>
                        <MenuItem value="Dollars">Dollar Amount</MenuItem>
                      </Select>
                      <FormHelperText
                        error={touched.productType && !!errors.productType}
                      >
                        {touched.productType && errors.productType}
                      </FormHelperText>
                    </FormControl>
                  </>
                ) : null}
                {values.showFuelLimits ? (
                  <FieldArray name="overrideLimits">
                    {({ push, remove }) => (
                      <Box>
                        {values.overrideLimits.map((productLimit, index) => (
                          <Box
                            key={index}
                            display="flex"
                            flexDirection="row"
                            mb={2}
                          >
                            <Box width="50%" mr={2}>
                              <FormControl
                                error={
                                  !!errors.overrideLimits &&
                                  isFormValues(errors) &&
                                  !!errors.overrideLimits[index]?.category
                                }
                                fullWidth
                              >
                                <InputLabel>Product</InputLabel>
                                <Select
                                  fullWidth
                                  name={`overrideLimits[${index}].category`}
                                  value={values.overrideLimits[index].category}
                                  style={{ width: '100%' }}
                                  onChange={handleChange}
                                  input={<OutlinedInput label="Product" />}
                                >
                                  {availableFuelProducts.map((product) => (
                                    <MenuItem
                                      key={product.productCategory}
                                      value={product.productCategory}
                                      disabled={values.overrideLimits.some(
                                        (pl) =>
                                          pl.category ===
                                            product.productCategory &&
                                          pl.category !== productLimit.category,
                                      )}
                                    >
                                      {product.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box
                                  color="#d32f2f"
                                  fontSize={12}
                                  ml="14px"
                                  mt="3px"
                                >
                                  <ErrorMessage
                                    name={`overrideLimits[${index}].category`}
                                  />
                                </Box>
                              </FormControl>
                            </Box>
                            <Box width="50%">
                              <Field
                                name={
                                  values.productType === 'Gallons'
                                    ? `overrideLimits.${index}.quantity`
                                    : `overrideLimits.${index}.amount`
                                }
                                as={TextField}
                                type="number"
                                label={
                                  values.productType === 'Gallons'
                                    ? 'Gallons'
                                    : '$ Amount'
                                }
                                fullWidth
                                variant="outlined"
                                error={
                                  !!errors.overrideLimits &&
                                  isFormValues(errors) && // Check if errors.overrideLimits is of type FormValues
                                  !!errors.overrideLimits[index] &&
                                  ((values.productType === 'Gallons' &&
                                    touched.overrideLimits?.[index]?.quantity &&
                                    !!errors.overrideLimits[index].quantity) ||
                                    (values.productType !== 'Gallons' &&
                                      touched.overrideLimits?.[index]?.amount &&
                                      !!errors.overrideLimits[index].amount))
                                }
                              />
                              <Box
                                color="#d32f2f"
                                fontSize={12}
                                ml="14px"
                                mt="3px"
                              >
                                <ErrorMessage
                                  name={
                                    values.productType === 'Gallons'
                                      ? `overrideLimits[${index}].quantity`
                                      : `overrideLimits[${index}].amount`
                                  }
                                />
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: {
                                  xs: 'column',
                                  sm: 'row',
                                },
                              }}
                            >
                              <IconButton
                                onClick={() => remove(index)}
                                disabled={index === 0}
                              >
                                <RemoveCircleIcon />
                              </IconButton>
                              {values.overrideLimits.length <
                                availableFuelProducts.length && (
                                <IconButton
                                  onClick={() =>
                                    push({
                                      category: null,
                                      amount: 0,
                                      quantity: 0,
                                    })
                                  }
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </FieldArray>
                ) : null}
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Button
                    size="small"
                    color="secondary"
                    sx={[
                      cancelButtonStyle,
                      {
                        px: 4,
                        py: 3,
                        width: '100%',
                        textTransform: 'uppercase',
                      },
                    ]}
                    onClick={() => {
                      if (!values.showNonFuelLimits) {
                        setFieldValue('showNonFuelLimits', true);
                      } else {
                        setFieldValue('nonFuelLimits', [
                          {
                            category: null,
                            amount: 0,
                            quantity: 0,
                          },
                        ]);
                        setFieldValue('productType', '');
                        setFieldValue('showNonFuelLimits', false);
                      }
                    }}
                  >
                    {!values.showNonFuelLimits
                      ? 'Add Non Fuel Limits'
                      : 'Hide Non Fuel Limts'}
                  </Button>
                </Box>
                {values.showNonFuelLimits ? (
                  <>
                    <Typography mb={2} ml={1} fontSize={14} fontWeight={600}>
                      NON FUEL LIMITS
                    </Typography>
                    <FieldArray name="nonFuelLimits">
                      {({ push, remove }) => (
                        <Box>
                          {values.nonFuelLimits.map((productLimit, index) => (
                            <Box
                              key={index}
                              display="flex"
                              flexDirection="row"
                              mb={2}
                            >
                              <Box width="50%" mr={2}>
                                <FormControl
                                  error={
                                    !!errors.nonFuelLimits &&
                                    touched.nonFuelLimits && // Check if the entire FieldArray is touched
                                    touched.nonFuelLimits[index] && // Check if the field at the specified index is touched
                                    isNonFuelFormValues(errors) &&
                                    !!errors.nonFuelLimits[index]?.category
                                  }
                                  fullWidth
                                >
                                  <InputLabel>Product</InputLabel>
                                  <Select
                                    fullWidth
                                    name={`nonFuelLimits.${index}.category`}
                                    value={values.nonFuelLimits[index].category}
                                    style={{ width: '100%' }}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Product" />}
                                  >
                                    {availableNonFuelProducts.map((product) => (
                                      <MenuItem
                                        key={product.productCategory}
                                        value={product.productCategory}
                                        disabled={values.nonFuelLimits.some(
                                          (pl) =>
                                            pl.category ===
                                              product.productCategory &&
                                            pl.category !==
                                              productLimit.category,
                                        )}
                                      >
                                        {product.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <Box
                                    color="#d32f2f"
                                    fontSize={12}
                                    ml="14px"
                                    mt="3px"
                                  >
                                    <ErrorMessage
                                      name={`nonFuelLimits[${index}].category`}
                                    />
                                  </Box>
                                </FormControl>
                              </Box>
                              <Box width="50%">
                                <Field
                                  name={`nonFuelLimits.${index}.amount`}
                                  as={TextField}
                                  type="number"
                                  label="$ Amount"
                                  fullWidth
                                  variant="outlined"
                                  error={
                                    !!errors.nonFuelLimits &&
                                    touched.nonFuelLimits && // Check if the entire FieldArray is touched
                                    touched.nonFuelLimits[index] && // Check if the field at the specified index is touched
                                    isNonFuelFormValues(errors) &&
                                    !!errors.nonFuelLimits[index]?.amount
                                  }
                                />
                                <Box
                                  color="#d32f2f"
                                  fontSize={12}
                                  ml="14px"
                                  mt="3px"
                                >
                                  <ErrorMessage
                                    name={`nonFuelLimits[${index}].amount`}
                                  />
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                  },

                                  justifyContent: {
                                    xs: 'flex-start',
                                  },
                                }}
                              >
                                <IconButton
                                  onClick={() => remove(index)}
                                  disabled={index === 0}
                                >
                                  <RemoveCircleIcon />
                                </IconButton>
                                {values.nonFuelLimits.length <
                                  availableNonFuelProducts.length && (
                                  <IconButton
                                    onClick={() =>
                                      push({
                                        category: null,
                                        amount: 0,
                                        quantity: 0,
                                      })
                                    }
                                  >
                                    <AddCircleIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </FieldArray>
                  </>
                ) : null}
                <Button
                  size="small"
                  color="secondary"
                  sx={[
                    cancelButtonStyle,
                    { mb: 2, py: 3, width: '100%', textTransform: 'uppercase' },
                  ]}
                  onClick={() => {
                    setShowExpirationDate(!showExpirationDate);
                    setFieldValue('expirationDate', '');
                  }}
                >
                  {showExpirationDate
                    ? 'Hide Expiration Date'
                    : 'Add Expiration Date'}
                </Button>
                {showExpirationDate ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Typography mb={2} ml={1} fontSize={15} fontWeight={600}>
                      TOKEN EXPIRATION DATE
                    </Typography>
                    <Box
                      mb={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <DatePicker
                        minDate={new Date()}
                        label="End Date"
                        value={values.expirationDate}
                        onChange={(newValue) =>
                          setFieldValue('expirationDate', newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            size="medium"
                            style={{ width: '50%', marginRight: 8 }}
                            {...params}
                            error={
                              touched.expirationDate && !!errors.expirationDate
                            }
                          />
                        )}
                      />
                      <TimePicker
                        label="End Time"
                        value={values.expirationDate}
                        onChange={(newValue) =>
                          setFieldValue('expirationDate', newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            size="medium"
                            style={{ width: '50%' }}
                            {...params}
                            error={
                              touched.expirationDate && !!errors.expirationDate
                            }
                          />
                        )}
                      />
                    </Box>
                    <FormHelperText
                      sx={{ ml: 2 }}
                      error={touched.expirationDate && !!errors.expirationDate}
                    >
                      {touched.expirationDate && errors.expirationDate}
                    </FormHelperText>
                  </LocalizationProvider>
                ) : null}
              </Box>
            </DialogContent>
            {oneTimeToken ? (
              <Box
                p={3}
                bgcolor="green"
                width={{ sm: 400, sx: '100%' }}
                mt={4}
                marginLeft={'auto'}
                marginRight={'auto'}
                borderRadius={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography color="white" fontWeight={600} fontSize={16}>
                  Success!
                </Typography>
                {isQuikQ ? (
                  <>
                    <Typography
                      color="white"
                      fontWeight={600}
                      fontSize={16}
                      mt={2}
                    >
                      Driver must enter start code: 98123
                    </Typography>
                    <Typography
                      color="white"
                      fontWeight={600}
                      fontSize={16}
                      mt={2}
                    >
                      Driver must enter PO #:
                    </Typography>
                  </>
                ) : (
                  <Typography
                    color="white"
                    fontWeight={600}
                    fontSize={16}
                    mt={2}
                  >
                    Here is your one time token:
                  </Typography>
                )}
                <Typography mt={2} color="white" fontWeight={600} fontSize={28}>
                  {oneTimeToken}
                </Typography>
              </Box>
            ) : null}
            <Dialog open={showConfirmCloseDialog}>
              <DialogTitle>Are Your Sure You Want To Close?</DialogTitle>
              <DialogContent>
                <Typography>
                  Make sure you save or copy your One Time Token before closing
                  this dialog.
                </Typography>
              </DialogContent>
              <DialogActions>
                <SecondaryButton
                  onClick={() => setShowConfirmCloseDialog(false)}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton onClick={() => handleClose(undefined)}>
                  Confirm
                </PrimaryButton>
              </DialogActions>
            </Dialog>
            <DialogActions>
              {oneTimeToken ? (
                <>
                  <PrimaryButton onClick={handleConfirmClose}>
                    Close
                  </PrimaryButton>
                </>
              ) : (
                <>
                  <SecondaryButton onClick={() => handleClose(undefined)}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </>
              )}
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
}
